import React from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    navigate("/dashboard");
  };

  return (
    <div className="Login-container">
      <div className="container">
        <div className="Login-left-panel">
          <h1>Welcome Back!</h1>
          <p>Please login to your account</p>
        </div>
        <div className="Login-right-panel">
          <div className="Login-form-group">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <div className="Login-input-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" />
              </div>
              <div className="Login-input-group">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" />
              </div>
              <div className="Login-button-container">
                <button type="submit" className="Login-submit-button">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
