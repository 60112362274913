import axios from "axios";

export const fetchData = async () => {
  try {
    const response = await axios.get("http://localhost:3001/api/podio/data");
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchFields = async () => {
  try {
  } catch (error) {
    console.log(error);
  }
};

export const syncData = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}api/podio/sync`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
