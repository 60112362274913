import React, { useState } from "react";
import "./Sidebar.css";

const Sidebar = ({ isOpen, onClose }) => {
  const [appId, setAppId] = useState("");
  const [appSecret, setAppSecret] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [formData, setFormData] = useState({});
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setShowAdditionalFields(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitted(true);
  };

  const handleUnsync = () => {
    setIsSubmitted(false);
    onClose();
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
      <form
        onSubmit={
          showAdditionalFields && !isSubmitted ? handleSubmit : handleNext
        }
      >
        <div className="dashboard-form-group">
          <label htmlFor="clientId" className="dashboard-form-label">
            Client ID:
          </label>
          <input
            type="text"
            id="clientId"
            name="clientId"
            className="dashboard-form-control"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            required
            disabled={isSubmitted}
          />
        </div>
        <div className="dashboard-form-group">
          <label htmlFor="clientSecret" className="dashboard-form-label">
            Client Secret:
          </label>
          <input
            type="text"
            id="clientSecret"
            name="clientSecret"
            className="dashboard-form-control"
            value={clientSecret}
            onChange={(e) => setClientSecret(e.target.value)}
            required
            disabled={isSubmitted}
          />
        </div>
        <div className="dashboard-form-group">
          <label htmlFor="appId" className="dashboard-form-label">
            App ID:
          </label>
          <input
            type="text"
            id="appId"
            name="appId"
            className="dashboard-form-control"
            value={appId}
            onChange={(e) => setAppId(e.target.value)}
            required
            disabled={isSubmitted}
          />
        </div>
        <div className="dashboard-form-group">
          <label htmlFor="appSecret" className="dashboard-form-label">
            App Secret:
          </label>
          <input
            type="text"
            id="appSecret"
            name="appSecret"
            className="dashboard-form-control"
            value={appSecret}
            onChange={(e) => setAppSecret(e.target.value)}
            required
            disabled={isSubmitted}
          />
        </div>
        {showAdditionalFields && (
          <>
            <div className="dashboard-form-group">
              <label htmlFor="title" className="dashboard-form-label">
                Title:
              </label>
              <select
                id="title"
                name="title"
                className="dashboard-form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                disabled={isSubmitted}
              >
                <option value="">Select</option>
                <option value="mr">Mr.</option>
                <option value="ms">Ms.</option>
                <option value="mrs">Mrs.</option>
              </select>
            </div>

            <div className="dashboard-form-group">
              <label htmlFor="category" className="dashboard-form-label">
                Category:
              </label>
              <select
                id="category"
                name="category"
                className="dashboard-form-control"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
                disabled={isSubmitted}
              >
                <option value="">Select</option>
                <option value="type1">Type 1</option>
                <option value="type2">Type 2</option>
              </select>
            </div>

            <div className="dashboard-form-group">
              <label htmlFor="description" className="dashboard-form-label">
                Description:
              </label>
              <select
                id="description"
                name="description"
                className="dashboard-form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                disabled={isSubmitted}
              >
                <option value="">Select</option>
                <option value="desc1">Description 1</option>
                <option value="desc2">Description 2</option>
              </select>
            </div>

            <div className="dashboard-form-group">
              <label htmlFor="date" className="dashboard-form-label">
                Date:
              </label>
              <select
                id="date"
                name="date"
                className="dashboard-form-control"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                disabled={isSubmitted}
              >
                <option value="">Select</option>
                <option value="2024-01-01">January 1, 2024</option>
                <option value="2024-01-02">January 2, 2024</option>
              </select>
            </div>
          </>
        )}
        <button
          type="submit"
          className="dashboard-submit-button"
          onClick={isSubmitted ? handleUnsync : undefined}
        >
          {isSubmitted ? "Unsync" : showAdditionalFields ? "Sync" : "Next"}
        </button>
      </form>
    </div>
  );
};

export default Sidebar;
