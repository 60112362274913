import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { scheduler } from "dhtmlx-scheduler";
import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
import "../components/Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Sidebar";
import { fetchData, syncData } from "../services/podio";

const Dashboard = () => {
  const [allEvents, setAllEvents] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const filteredEventsRef = useRef([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    scheduler.config.xml_date = "%Y-%m-%d %H:%i";
    scheduler.init("scheduler_here", new Date(), "week");
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (dataFetched) {
      filterAndSetEvents(allEvents, searchTerm, filterValue);
    }
  }, [searchTerm, filterValue, allEvents, dataFetched]);

  const fetchInitialData = async () => {
    try {
      const events = await fetchData();
      console.log("events", events);
      if (events) {
        console.log("event in condition", events);
        setDataFetched(true);
        setAllEvents(events);
        filterAndSetEvents(events, searchTerm, filterValue);
      } else {
        console.warn("No events found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const filterAndSetEvents = (events, search, filter) => {
    let filtered = events || [];
    if (search) {
      filtered = filtered.filter((event) =>
        event.text?.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (filter !== "all") {
      filtered = filtered.filter((event) => event?.progress === filter);
    }

    filteredEventsRef.current = filtered;
    scheduler.clearAll();
    scheduler.parse(filtered, "json");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    const value =
      e.target.value === "all" ? "all" : parseInt(e.target.value, 10);
    setFilterValue(value);
  };

  const handleSyncClick = async () => {
    try {
      const response = await syncData();
      await fetchInitialData(); // Fetch data again after syncing
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (error) {
      console.error("Error syncing data:", error);
    }
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div>
      <div
        id="scheduler_here"
        className="dhx_cal_container"
        style={{ width: "100%", height: "950px" }}
      >
        <div className="dhx_cal_navline">
          <div className="dhx_cal_prev_button">&nbsp;</div>
          <div className="dhx_cal_today_button"></div>
          <div className="dhx_cal_next_button">&nbsp;</div>
          <div className="dhx_cal_date"></div>
          <div className="dhx_cal_tab" name="day_tab"></div>
          <div className="dhx_cal_tab" name="week_tab"></div>
          <div className="dhx_cal_tab" name="month_tab"></div>

          {dataFetched && (
            <>
              <input
                style={{ height: "36px", marginLeft: "10px" }}
                type="text"
                id="event_search"
                placeholder="Search events"
                onChange={handleSearchChange}
              />
              <select
                id="event_filter"
                style={{ height: "36px", marginLeft: "10px" }}
                onChange={handleFilterChange}
              >
                <option value="all">All</option>
                <option value={1}>Not started yet</option>
                <option value={2}>On going</option>
                <option value={3}>Completed</option>
              </select>
            </>
          )}
          <div className="tooltip-container">
            <button
              id="sync_button"
              style={{ height: "36px", marginLeft: "10px" }}
              className="tooltip-trigger"
              onClick={handleSyncClick}
            >
              <FontAwesomeIcon icon={faSync} />
            </button>
            <div className="tooltip-content">Sync Data</div>
          </div>
        </div>
        <div className="dhx_cal_header"></div>
        <div className="dhx_cal_data"></div>
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
      </div>
      {showNotification && (
        <div className="notification">Data syncing completed!</div>
      )}
    </div>
  );
};

export default Dashboard;
